import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NzIconModule, NzIconService } from 'ng-zorro-antd/icon';
import { NzIconTestModule } from 'ng-zorro-antd/icon/testing';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import version from '../../package.json';
import { icons } from './components/icons';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, NzIconModule, NzLayoutModule, NzMenuModule, NzIconTestModule],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  isCollapsed = false;

  constructor(private iconService: NzIconService) {}

  ngOnInit(): void {
    this.loadIcons();

    const APP_VERSION = version;
    if (APP_VERSION) {
      (window as any).APP_VERSION = APP_VERSION;
    }
  }

  loadIcons(): void {
    if (icons) {
      icons.forEach(icon => {
        this.iconService.addIconLiteral(icon.key, icon.icon);
      });
    }
  }
}
