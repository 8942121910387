import { CanActivateFn, Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SignUpGuard {
  constructor(private router: Router) {}

  canActivate: CanActivateFn = (route, state) => {
    const queryParams = route.queryParams;
    const hasSecretKeyParam = queryParams.hasOwnProperty('secret_key');

    if (!hasSecretKeyParam && route.routeConfig?.path === 'sign-up') {
      this.router.navigate(['/access-code']);
      return false;
    }

    return true;
  };
}
