import { CanActivateFn } from '@angular/router';
// auth.guard.ts

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { APP_ROUTES } from '../routes';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}

  canActivate: CanActivateFn = (route, state) => {
    const userRole = this.authService.getAuthUserRole();

    if (this.authService.isAuthenticated()) {
      const expectedRole = route.data?.['expectedRole'];

      if (!expectedRole || expectedRole.includes(userRole)) {
        return true;
      } else {
        this.router.navigate([APP_ROUTES.DASHBOARD.PATH + '/home']);
        return false;
      }
    } else {
      this.router.navigate([APP_ROUTES.LOGIN.PATH]);
      return false;
    }
  };
}
