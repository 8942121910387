export const uploadIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <g clip-path="url(#clip0_2583_57472)">
    <path d="M12.1691 10.5822C12.149 10.5565 12.1234 10.5358 12.0942 10.5216C12.065 10.5073 12.0329 10.4999 12.0003 10.4999C11.9678 10.4999 11.9357 10.5073 11.9065 10.5216C11.8772 10.5358 11.8516 10.5565 11.8316 10.5822L8.83159 14.3777C8.80686 14.4093 8.79152 14.4472 8.78731 14.4871C8.78311 14.527 8.79021 14.5672 8.80781 14.6033C8.82542 14.6393 8.85281 14.6697 8.88685 14.6909C8.9209 14.7121 8.96023 14.7233 9.00034 14.7232H10.9798V21.2161C10.9798 21.3339 11.0762 21.4304 11.1941 21.4304H12.8012C12.9191 21.4304 13.0155 21.3339 13.0155 21.2161V14.7259H15.0003C15.1798 14.7259 15.2789 14.5197 15.1691 14.3804L12.1691 10.5822Z" fill="#450F8A"/>
    <path d="M20.0196 8.10314C18.7929 4.86742 15.667 2.56653 12.0054 2.56653C8.34375 2.56653 5.21786 4.86474 3.99107 8.10046C1.69554 8.70314 0 10.7951 0 13.2808C0 16.2406 2.39732 18.638 5.35446 18.638H6.42857C6.54643 18.638 6.64286 18.5415 6.64286 18.4237V16.8165C6.64286 16.6987 6.54643 16.6022 6.42857 16.6022H5.35446C4.45179 16.6022 3.60268 16.2433 2.97054 15.5924C2.34107 14.9442 2.00625 14.071 2.03571 13.1656C2.05982 12.4585 2.30089 11.7942 2.7375 11.2344C3.18482 10.6638 3.81161 10.2487 4.50804 10.0638L5.52321 9.79867L5.89554 8.81831C6.12589 8.2076 6.44732 7.63706 6.85179 7.1201C7.25108 6.60771 7.72407 6.15729 8.25536 5.78349C9.35625 5.00939 10.6527 4.59956 12.0054 4.59956C13.358 4.59956 14.6545 5.00939 15.7554 5.78349C16.2884 6.15849 16.7598 6.60849 17.1589 7.1201C17.5634 7.63706 17.8848 8.21028 18.1152 8.81831L18.4848 9.79599L19.4973 10.0638C20.9491 10.4549 21.9643 11.7755 21.9643 13.2808C21.9643 14.1674 21.6187 15.0031 20.992 15.6299C20.6846 15.9391 20.3189 16.1842 19.9162 16.3511C19.5134 16.518 19.0815 16.6034 18.6455 16.6022H17.5714C17.4536 16.6022 17.3571 16.6987 17.3571 16.8165V18.4237C17.3571 18.5415 17.4536 18.638 17.5714 18.638H18.6455C21.6027 18.638 24 16.2406 24 13.2808C24 10.7978 22.3098 8.70849 20.0196 8.10314Z" fill="#450F8A"/>
  </g>
  <defs>
    <clipPath id="clip0_2583_57472">
      <rect width="24" height="24" fill="white"/>
    </clipPath>
  </defs>
</svg>`;

export const uploadIconHover = `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <g clip-path="url(#clip0_2583_57472)">
    <path d="M12.1691 10.5822C12.149 10.5565 12.1234 10.5358 12.0942 10.5216C12.065 10.5073 12.0329 10.4999 12.0003 10.4999C11.9678 10.4999 11.9357 10.5073 11.9065 10.5216C11.8772 10.5358 11.8516 10.5565 11.8316 10.5822L8.83159 14.3777C8.80686 14.4093 8.79152 14.4472 8.78731 14.4871C8.78311 14.527 8.79021 14.5672 8.80781 14.6033C8.82542 14.6393 8.85281 14.6697 8.88685 14.6909C8.9209 14.7121 8.96023 14.7233 9.00034 14.7232H10.9798V21.2161C10.9798 21.3339 11.0762 21.4304 11.1941 21.4304H12.8012C12.9191 21.4304 13.0155 21.3339 13.0155 21.2161V14.7259H15.0003C15.1798 14.7259 15.2789 14.5197 15.1691 14.3804L12.1691 10.5822Z" fill="#450F8A"/>
    <path d="M20.0196 8.10314C18.7929 4.86742 15.667 2.56653 12.0054 2.56653C8.34375 2.56653 5.21786 4.86474 3.99107 8.10046C1.69554 8.70314 0 10.7951 0 13.2808C0 16.2406 2.39732 18.638 5.35446 18.638H6.42857C6.54643 18.638 6.64286 18.5415 6.64286 18.4237V16.8165C6.64286 16.6987 6.54643 16.6022 6.42857 16.6022H5.35446C4.45179 16.6022 3.60268 16.2433 2.97054 15.5924C2.34107 14.9442 2.00625 14.071 2.03571 13.1656C2.05982 12.4585 2.30089 11.7942 2.7375 11.2344C3.18482 10.6638 3.81161 10.2487 4.50804 10.0638L5.52321 9.79867L5.89554 8.81831C6.12589 8.2076 6.44732 7.63706 6.85179 7.1201C7.25108 6.60771 7.72407 6.15729 8.25536 5.78349C9.35625 5.00939 10.6527 4.59956 12.0054 4.59956C13.358 4.59956 14.6545 5.00939 15.7554 5.78349C16.2884 6.15849 16.7598 6.60849 17.1589 7.1201C17.5634 7.63706 17.8848 8.21028 18.1152 8.81831L18.4848 9.79599L19.4973 10.0638C20.9491 10.4549 21.9643 11.7755 21.9643 13.2808C21.9643 14.1674 21.6187 15.0031 20.992 15.6299C20.6846 15.9391 20.3189 16.1842 19.9162 16.3511C19.5134 16.518 19.0815 16.6034 18.6455 16.6022H17.5714C17.4536 16.6022 17.3571 16.6987 17.3571 16.8165V18.4237C17.3571 18.5415 17.4536 18.638 17.5714 18.638H18.6455C21.6027 18.638 24 16.2406 24 13.2808C24 10.7978 22.3098 8.70849 20.0196 8.10314Z" fill="#450F8A"/>
  </g>
  <defs>
    <clipPath id="clip0_2583_57472">
      <rect width="24" height="24" fill="white"/>
    </clipPath>
  </defs>
</svg>`;
