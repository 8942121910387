import { controlsIcon, controlsIconHover } from './controls';
import { homeIcon, homeIconHover } from './home';
import { oneClickIcon } from './oneClick';
import { policiesIcon, policiesIconHover } from './policies';
import { settingIcon, settingIconHover } from './setting';
import { uploadIcon } from './upload';
import { awsIcon, googleIcon, microsoftIcon } from './vendors';
export interface IIcons {
  key: string;
  icon: string;
}
export const icons: IIcons[] = [
  {
    key: 'oppos:home',
    icon: homeIcon
  },
  {
    key: 'oppos:home-hover',
    icon: homeIconHover
  },
  {
    key: 'oppos:policies',
    icon: policiesIcon
  },
  {
    key: 'oppos:policies-hover',
    icon: policiesIconHover
  },
  {
    key: 'oppos:controls',
    icon: controlsIcon
  },
  {
    key: 'oppos:controls-hover',
    icon: controlsIconHover
  },
  {
    key: 'oppos:setting',
    icon: settingIcon
  },
  {
    key: 'oppos:setting-hover',
    icon: settingIconHover
  },
  {
    key: 'oppos:one-click',
    icon: oneClickIcon
  },
  {
    key: 'oppos:upload',
    icon: uploadIcon
  },
  {
    key: 'oppos:google',
    icon: googleIcon
  },
  {
    key: 'oppos:microsoft',
    icon: microsoftIcon
  },
  {
    key: 'oppos:aws',
    icon: awsIcon
  }
];
